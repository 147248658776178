@import 'src/assets/css/styles.scss';

.form-group {
  .form-input {
    fieldset {
      top: 0;
      border: none;
    }

    .MuiOutlinedInput-root.Mui-disabled {
      border-color: var(--grey85);
      border-radius: 8px;
    }

    .MuiFormControl-root {
      border: 1px solid #BDBCBC;
      border-radius: 4px;

      .MuiInputLabel-formControl {
        transform: translate(8px, 22px) scale(1);
        font-family: "Brandon Grotesque";
        font-size: 16px;
        font-weight: 390;
        line-height: 24px;
        color: #787475;
      }

      .MuiInputLabel-formControl.MuiInputLabel-shrink {
        transform: translate(8px, 4px) scale(0.75);
      }

      .MuiInputBase-root {
        .MuiInputBase-input {
          padding: 22.5px 8px;
          color: #231F20;

          &.MuiInputBase-inputMultiline {
            padding: 22.5px 0px;
          }
        }

        .Mui-disabled {
          background-color: transparent !important;
        }

        .MuiChip-root {
          height: 22px;
        }
      }

      .MuiInputBase-root.MuiOutlinedInput-multiline {
        padding: 22.5px 8px;
      }

      .MuiInputBase-root::before,
      .MuiInputBase-root::after {
        display: none;
      }

      .Mui-disabled {
        background-color: transparent !important;
      }

      .MuiFormLabel-root.Mui-focused {
        color: #555152;
      }

      .MuiFormLabel-root.Mui-error {
        color: #A83008;
      }

      .MuiInputBase-input.MuiSelect-select {
        padding: 13.5px 8px;
        min-width: 120px;
      }

      .MuiInputBase-input.MuiSelect-select:focus {
        background-color: transparent;
      }

      .MuiAutocomplete-inputRoot {
        .MuiAutocomplete-input {
          padding: 13.5px 8px;
        }
      }
    }

    .MuiFormControl-root:has(input.Mui-disabled),
    .MuiFormControl-root:has(.MuiSelect-select.Mui-disabled) {
      background-color: #e9e9e9;
    }

    .MuiFormControl-root:has(input:not([value=""])) {
      .MuiFormLabel-root {
        transform: translate(8px, 4px) scale(0.75);
      }
    }

    .MuiFormControl-root:has(.MuiFormLabel-root.Mui-error) {
      border-color: #A83008;
    }

    .textarea {
      border: 1px solid #BDBCBC;
      border-radius: 4px;
    }

    .textarea:focus-visible {
      outline: 0;
    }
  }

  .helper-text {
    margin: 5px 0px 0px;
  }

  .error-text {
    margin: 5px 0px 0px;
    font-family: "Brandon Grotesque";
    font-size: 14px;
    font-weight: 450;
    line-height: 20.02px;
    color: #A83008;
  }
}

.form-group.error-border {
  .form-input {
    .MuiFormControl-root {
      border-color: #A83008;
    }
  }
}