// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Playfair+Display:wght@700;900&display=swap');
// webfonts

@font-face {
  font-family: 'LTC Caslon';
  src: local('LTC Caslon'), url('../webfonts/LTCCaslon.ttf') format('truetype');
}

@font-face {
  font-family: 'LTC Caslon Italic';
  src: url('../webfonts/LTCCaslonProItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: local('Brandon Grotesque'), url('../webfonts/Brandon-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'BN Bergen St';
  src: local('BN Bergen St'), url('../webfonts/BNBergenSt.otf') format('opentype');
}
