@import './fonts.scss';
@import './variables.scss';

body {
  --text-color: #222;
  --text-color-600: #555152;
  --bkg-color: rgba(255, 255, 255, 0.98);
  --bkg-paper-color: #f1f1f1;
  --linear-gradient: linear-gradient(rgba(255, 255, 255, 0.45), rgba(228, 224, 224, 0.45));
  --bkg-transparent: rgba(255, 255, 255, 0.9);
  --border: #f2f1ea;
  --petal: #ff9977;
  --stem: #5e5929;
  --stem-200: #dad8ca;

  // new design system
  --bkg-color-new: #f2f1ea;
  --bkg-footer-color: #e5e3d9;
  --bg-disabled: #9b9985;
  --night-600: #555152;
  --error: #d7462d;
  --text-error: #a83008;
  --text-grey: #231f20;

  // defined new colors
  --secondary-linen: #fffefa;
  --secondary-night: #231f20;
  --secondary-linen2: #f2f1ea;
  --neutrals-night100: #cfcfcf;
  --neutrals-night200: #c6c5c5;
  --neutrals-night300: #bdbcbc;
  --neutrals-night400: #898586;
  --neutrals-night500: #787475;
  --neutrals-night700: #444041;
  --semantic-success: #4f4b23;

  --poppy-red: #d7462d;
  --poppy-red-light: rgba(215, 70, 45, 0.75);
  --poppy-red-dark: darken(#d7462d, 0.5);
  --poppy-red-light-light: lighten(rgba(215, 70, 45, 0.75), 0.5);
  --dark-blue: #062697;
  --outline-gray: #b8b8b8;

  --gainsboro: #dadada;
  --grey85: #d9d9d9;
}

body.dark-theme {
  --text-color: #fff;
  --text-color-600: #fff;
  --bkg-color: #303030;
  --bkg-paper-color: #505050;
  --linear-gradient: linear-gradient(#303030, #303030);
  --border: #231f20;

  --bkg-color-new: #231f20;
  --bkg-footer-color: #303030;
}

h1,
h2 {
  font-family: $headline-font !important;
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: var(--text-color);
}

body,
#root {
  height: 100vh;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: auto !important;
}

@for $j from 0 to 101 {
  // margin
  .mb-#{$j}px {
    margin-bottom: 1px * $j !important;
  }

  .mb-#{$j}rem {
    margin-bottom: 0.1rem * $j !important;
  }

  .ml-#{$j}px {
    margin-left: 1px * $j !important;
  }

  .mt-#{$j}px {
    margin-top: 1px * $j !important;
  }

  .mt-#{$j}rem {
    margin-top: 0.1rem * $j !important;
  }

  .mr-#{$j}px {
    margin-right: 1px * $j !important;
  }

  .mlr-#{$j}px {
    margin-left: 1px * $j !important;
    margin-right: 1px * $j !important;
  }

  .mlr-#{$j}rem {
    margin-left: 0.1rem * $j !important;
    margin-right: 0.1rem * $j !important;
  }

  .mtb-#{$j}px {
    margin-top: 1px * $j !important;
    margin-bottom: 1px * $j !important;
  }

  .mt-#{$j}px-auto {
    margin: 1px * $j auto;
  }

  // padding
  .p-#{$j}px {
    padding: 1px * $j !important;
  }
  .pb-#{$j}px {
    padding-bottom: 1px * $j !important;
  }

  .pl-#{$j}px {
    padding-left: 1px * $j !important;
  }

  .pt-#{$j}px {
    padding-top: 1px * $j !important;
  }

  .pr-#{$j}px {
    padding-right: 1px * $j !important;
  }

  .plr-#{$j}px {
    padding-left: 1px * $j !important;
    padding-right: 1px * $j !important;
  }

  .plr-#{$j}rem {
    padding-left: 0.1rem * $j !important;
    padding-right: 0.1rem * $j !important;
  }

  .ptb-#{$j}px {
    padding-top: 1px * $j !important;
    padding-bottom: 1px * $j !important;
  }

  // width
  .w-#{$j} {
    width: 1% * $j !important;
  }

  .w-#{$j}px {
    width: 1px * $j !important;
  }

  // height
  .h-#{$j} {
    height: 1% * $j !important;
  }

  .h-#{$j}px {
    height: 1px * $j !important;
  }
}

// font-size
@for $j from 10 to 40 {
  .fz-#{$j}px {
    font-size: 1px * $j !important;
  }
}

.error-text {
  color: var(--text-error) !important;
  font-family: 'Brandon Grotesque';
  font-size: 14px;
  font-weight: 450;
  line-height: 24px;
}

.button-stem {
  text-align: center;
  opacity: 1;
  padding: 15px 24px;
  background-color: rgba(94, 89, 41, 1);
  border: 1px solid rgba(94, 89, 41, 1);
  color: #fffefa;
  font-family: BN Bergen St;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  cursor: pointer;
  &:active {
    background: rgba(94, 89, 41, 1);
    border: 1px solid rgba(94, 89, 41, 1);
  }
  &:focus {
    border: 1px solid rgba(94, 89, 41, 0.51);
  }
  &:disabled {
    color: #f2f1ea;
    background: rgba(94, 89, 41, 0.51);
    border: 1px solid rgba(94, 89, 41, 0.51);
  }
  &.loading {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto 8px auto auto;
      border: 4px solid transparent;
      border-top-color: #ffffff;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
  }

  &.secondary {
    border: 1px solid #5e5929;
    background: #fffefa;
    color: #5e5929;
  }
}

.floating-label {
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-weight: 390;
  line-height: 24px;
  color: #787475;
  &.focused {
    font-size: 10px;
    line-height: 14.3px;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

// new defined typography
.large-text {
  font-family: $headline-font;
  font-size: 60px;
  font-weight: 400;
  line-height: 90px;
}

.medium-text {
  font-family: $headline-font;
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
}

.large-headline {
  font-family: $headline-font;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}

.medium-headline {
  font-family: $headline-font;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}

.small-headline {
  font-family: $headline-font;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.eyebrow-headline {
  font-family: $headline-font;
  font-size: 16px;
  font-style: italic;
  font-weight: 405;
  line-height: 24px;
  letter-spacing: 0.1em;
}

.p-large {
  font-family: $text-font;
  font-size: 20px;
  font-weight: 390;
  line-height: 30px;
}

.p-medium {
  font-family: $text-font;
  font-size: 16px;
  font-weight: 390;
  line-height: 24px;
}

.caption {
  font-family: $text-font;
  font-size: 14px;
  font-weight: 390;
  line-height: 21px;
}

.p-medium-bold {
  font-family: $text-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.label-large {
  font-family: $label-font;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.2em;
}

.label-medium {
  font-family: $label-font;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.2em;
}

.label {
  font-family: $text-font;
  font-size: 10px;
  font-weight: 390;
  line-height: 21px;
}

// picker
.day-picker-wrapper {
  margin-top: 6px;

  .DayPickerInput {
    width: 100%;
    font-family: $headline-font;
    color: #000000;
    font-size: 16px;
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 2;
  }

  .DayPicker-Day.DayPicker-Day--selected,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #eb4b32 !important;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #eb4b32 !important;
    color: #fff;
  }

  .input-field {
    padding-left: 20px;
  }

  .input-field::placeholder {
    text-align: left;
    padding-right: 55px;
    color: #000;
    opacity: 1;
  }

  .input-icons {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    position: relative;

    .icon {
      position: absolute;
      padding: 6px;
      text-align: center;
      right: 3px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      border-radius: 0px;
      outline: 0;
      width: 100%;
      box-sizing: border-box;
    }

    .MuiInputLabel-filled {
      transform: translate(12px, 17px) scale(1);
      color: #555152;
    }

    .MuiInputLabel-filled.MuiInputLabel-shrink {
      transform: translate(12px, 7px) scale(0.75);
    }

    .MuiInputBase-root {
      background: transparent;
      transition: none;
    }
    .MuiInputBase-root::before,
    .MuiInputBase-root::after {
      display: none;
    }

    .basic {
      background: transparent;
      border: 1px solid #bdbcbc;
      border-radius: 4px;
      color: #000;
      padding: 8px 30px 8px 8px;
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 390;
      font-size: 16px;
      line-height: 24px;
    }
    .basic::placeholder {
      color: #787475;
    }
  }
}
