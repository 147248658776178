@import '/src/assets/css/styles.scss';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(228, 224, 224, 0.5)),
    url('https://weddings-products.s3.amazonaws.com/product_catalog/bulkUpload122220/Poppy-Blush-0109.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    text-decoration-color: var(--poppy-red-light);
    margin: 0;
    margin-bottom: 2rem;
  }

  .card {
    display: flex;
    padding: 5rem;
    width: 33%;
    min-height: 25%;
    flex-direction: column;
    align-items: center;

    button {
      height: 3rem;
      width: 50%;
      margin-top: 2rem;
    }
  }
}

// /* iPads (portrait and landscape) ----------- */
// @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
//     .login .title{
//         color: red;
//     }
// }

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-device-width: 700px) {
  .login .card {
    padding: 2rem;
    width: 100%;
    margin: 0 1rem;

    button {
      width: 100%;
    }
  }
}
