table.style-guide {
  background-color: #f7f7f7;
  width: 100%;
  border-top: 1px solid #cfcfcf;
  color: #444041;
  thead th {
    font-family: 'Brandon Grotesque';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 24px 8px;
    &:first-child {
      text-align: left;
    }
  }
  tbody,
  tfoot {
    tr {
      border-bottom: 1px solid #cfcfcf;
    }
    td {
      padding: 12px 24px;
      text-align: center;
      border-bottom: 1px solid #cfcfcf;
      &:first-child {
        text-align: left;
      }
    }
  }
}
