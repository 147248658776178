.navbar {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0px 1rem;
  box-sizing: border-box;
  background-color: #231f20;

  img {
    cursor: pointer;
    height: 3rem;
  }

  .navbar-logo {
    width: 270px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    cursor: pointer;
  }

  button {
    height: 2rem;
  }
  .MuiButton-label {
    color: #fff;
    text-transform: none;
  }
}
#nav-dropdown-menu {
  ul {
    li {
      padding: 0;
      .menu-item {
        padding: 6px 16px;
        width: 100%;
      }
    }
  }
}
