@import '/src/assets/css/styles.scss';
@import '/src/assets/css/table.scss';

// uncomment this for temp fix for live development (only for live dev)
// issue iframe block the webpage
// body > iframe {
//   display: none;
// }

// Globals
// Override material-ui
.MuiButton-containedPrimary {
  background-color: var(--poppy-red-light) !important;
}

.MuiButton-containedPrimary:hover {
  background-color: var(--poppy-red) !important;
}

.MuiButton-outlinedSecondary {
  border-color: var(--poppy-red) !important;
  color: var(--poppy-red) !important;
}

.Mui-disabled {
  background-color: lightgray !important;
}

.MuiCard-root {
  padding: 1rem;
}

.MuiInput-root {
  font-family: 'Nunito';
}

.MuiSnackbar-root {
  z-index: 99999 !important;
}

.MuiAccordion-root {
  .MuiAccordionSummary-content {
    margin: 0;
  }
}

.MuiCheckbox-root {
  .MuiSvgIcon-root {
    fill: #444041;
  }

  &.Mui-checked {
    .MuiSvgIcon-root {
      fill: #ff9977;
    }
  }
}

.center {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-decoration-color: var(--poppy-red-light);
}

.subhead {
  text-align: center;
  font-size: 20px;
  margin: 0.5rem;
}

button.hidden:hover,
button.hidden:active {
  background-color: transparent;
}

.skeleton-line {
  height: 2rem;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

#copy-data {
  position: fixed;
  opacity: 0;
}

.oval-item {
  border-radius: 25px;
  padding: 2px 7px;
  white-space: nowrap;
  height: fit-content;
  margin-right: 5px;
  margin-bottom: 5px;
}

.bg-white {
  background: #fcf6ed;
  border: 1px solid #ccc;
  border-radius: 24px;
}

.bg-cream {
  background: #f5d9c7;
  border: 1px solid #f5d9c7;
}

.bg-blush {
  background: #efcfcf;
  border: 1px solid #efcfcf;
}

.bg-pink {
  background: #e877af;
  border: 1px solid #e877af;
}

.bg-lightpink {
  background: #ffb6c1;
  border: 1px solid #ffb6c1;
}

.bg-red {
  background: #d42828;
  border: 1px solid #d42828;
}

.bg-burgundy {
  background: #931f1f;
  border: 1px solid #931f1f;
}

.bg-orange {
  background: #fb6b1b;
  border: 1px solid #fb6b1b;
}

.bg-terracotta {
  background: #e2725b;
  border: 1px solid #e2725b;
}

.bg-peach {
  background: #f2bb9b;
  border: 1px solid #f2bb9b;
}

.bg-coral {
  background: #f88379;
  border: 1px solid #f88379;
}

.bg-yellow {
  background: #f7cd4b;
  border: 1px solid #f7cd4b;
}

.bg-green {
  background: #4f5b36;
  border: 1px solid #4f5b36;
}

.bg-darkcreen {
  background: #006400;
  border: 1px solid #006400;
}

.bg-blue {
  background: #0f4d82;
  border: 1px solid #0f4d82;
}

.bg-lightblue {
  background: #99beef;
  border: 1px solid #99beef;
}

.bg-purple {
  background: #5f277f;
  border: 1px solid #5f277f;
}

.bg-darkpurple {
  background: #301934;
  border: 1px solid #301934;
  color: #aaa;
}

.bg-brown {
  background: #964b00;
  border: 1px solid #964b00;
}

.bg-hotpink {
  background: #d9257f;
  border: 1px solid #d9257f;
}

.bg-lavender {
  background: #d9d2e9;
  border: 1px solid #d9d2e9;
}

.bg-tintedred {
  background: #d10d2f;
  border: 1px solid #d10d2f;
}

.bg-gold {
  background: #cf9e52;
  border: 1px solid #cf9e52;
}

.bg-lightlavender {
  background: #dcd0ff;
  border: 1px solid #dcd0ff;
}

.bg-darkorange {
  background: #ff8c00;
  border: 1px solid #ff8c00;
}

.bg-magenta {
  background: #ff00ff;
  border: 1px solid #ff00ff;
}

.bg-darkblue {
  background: #193380;
  border: 1px solid #193380;
  color: #aaa;
}

.bg-platinum {
  background: #e5e4e2;
  border: 1px solid #e5e4e2;
}

.bg-darkpink {
  background: #e75480;
  border: 1px solid #e75480;
}

.bg-darkred {
  background: #8b0000;
  border: 1px solid #8b0000;
  color: #aaa;
}

.bg-apricot {
  background: #fbceb1;
  border: 1px solid #fbceb1;
}

.bg-champagne {
  background: #f7e7ce;
  border: 1px solid #f7e7ce;
}

.bg-lightyellow {
  background: #ffffe0;
  border: 1px solid #ffffe0;
}

.bg-grey {
  background: #808080;
  border: 1px solid #808080;
}

.bg-gray {
  background: #c4c4c7;
  border: 1px solid #c4c4c7;
}

.bg-lightbrown {
  background: #c89d7c;
  border: 1px solid #c89d7c;
}

.bg-mediumpink {
  background: #f36196;
  border: 1px solid #f36196;
}

.bg-darkgreen {
  background: #006400;
  border: 1px solid #006400;
  color: #aaa;
}

.bg-mauve {
  background: #e0b0ff;
  border: 1px solid #e0b0ff;
}

.bg-lightgreen {
  background: #90ee90;
  border: 1px solid #90ee90;
}

.bg-lightpinkblush {
  background: #e8c7c8;
  border: 1px solid #e8c7c8;
}

.bg-bronze {
  background: #cd7f32;
  border: 1px solid #cd7f32;
}

.bg-copper {
  background: #b87333;
  border: 1px solid #b87333;
}

.bg-tintedcopper {
  background: #da8a67;
  border: 1px solid #da8a67;
}

.bg-black {
  background: #262629;
  border: 1px solid #262629;
  color: #aaa;
}

.bg-mixed {
  background: #fff;
  border: 1px solid #ccc;
}

.bg-fuchsia {
  background: #ff00ff;
  border: 1px solid #ff00ff;
}

.bg-silver {
  background: #c0c0c0;
  border: 1px solid #c0c0c0;
}

.bg-beige {
  background: #f5f5dc;
  border: 1px solid #f5f5dc;
}

.bg-salmon {
  background: #fa8072;
  border: 1px solid #fa8072;
}

.bg-darkpeach {
  background: #de7e5d;
  border: 1px solid #de7e5d;
}

.bg-variegated {
  background: #747d5a;
  border: 1px solid #747d5a;
}

.bg-bicolor {
  background: #fff;
  border: 1px solid #ccc;
}

.bg-darkyellow {
  background: #9b870c;
  border: 1px solid #9b870c;
}

.bg-lightpeach {
  background: #fbefe4;
  border: 1px solid #fbefe4;
}

.bg-terracotta {
  background: #d97557;
  border: 1px solid #d97557;
}

.bg-fuschia {
  background: #ff00ff;
  border: 1px solid #ff00ff;
}

.bg-rust {
  background: #cb4926;
  border: 1px solid #cb4926;
}

.bg-eggplant {
  background: #3e1e45;
  border: 1px solid #3e1e45;
}

.bg-limegreen {
  background: #32cd32;
  border: 1px solid #32cd32;
}

.text-capitalize {
  text-transform: capitalize;
}

.bg-image {
  background-image: var(--linear-gradient),
    url('https://weddings-products.s3.amazonaws.com/product_catalog/bulkUpload122220/Poppy-Nov19-0037.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  width: 100%;
}

.inline-link {
  font-family: 'Brandon Grotesque';
  font-size: 16px;
  font-weight: 390;
  line-height: 18px;
  color: #5e5929;
  cursor: pointer;

  &.underline {
    text-decoration: underline;
    text-decoration-color: unset;
    text-underline-offset: unset;
  }
}

.relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.d-gap-5 {
  gap: 5px;
}

.d-gap-10 {
  gap: 10px;
}

.min-height-300 {
  min-height: 300px;
}

.justify-align-space-between {
  justify-content: space-between;
  align-items: center;
}

.justify-align-center {
  justify-content: center;
  align-items: center;
}

.tooltip-item-text {
  font-family: Brandon Grotesque;
  font-size: 14px;
  font-weight: 390;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
  color: #231F20;
  margin-bottom: 5px;
  margin-top: 5px;
}
