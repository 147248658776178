.error {
  z-index: 9999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  position: absolute;
  font-size: 2rem;
  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--bkg-color);
    // border: 1px solid var(--poppy-red-dark);
    padding: 3rem 5rem 5.5rem;
    border-radius: 7px;
  }
}
