// loader
/* [FULL SCREEN SPINNER] */
.full_screen_loading {
  position: fixed;
  display: flex;
  top: 0px;
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  z-index: 99999;
  background: var(--bkg-color);
  text-align: center;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .icon-logo {
    width: 16rem;
    height: 16rem;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
